<template>
  <DefaultWrapper v-bind="$props">
    <slot />
  </DefaultWrapper>
</template>

<script>
import DefaultWrapper from '~contemporaryTheme/components/DefaultWrapper.vue'

export default {
  components: {
    DefaultWrapper,
  },
  props: {
    page: {
      type: String,
      required: true,
    },
  },
}
</script>
