<template>
  <div class="max-w-screen-xl mx-auto flex justify-center flex-col px-4 lg:flex-row md:pt-12 pt-0 md:pb-8  ">
    <section
      ref="content"
      tabindex="-1"
      class="lg:w-2/3 w-full content-container theme-page-content pb-0"
      aria-label="Page content"
    >
      <slot />
    </section>
  </div>
</template>

<script>
export default {
  // We will need this prop for stanford later with a refactor to enable sidebar
  props: {
    page: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.rec-block {
  @apply text-gray-900 px-4 py-2;
}
</style>
